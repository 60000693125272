<template>
  <div class="article-list__wrapper">
    <div class="main">
      <p class="bread">
        <a :href="'/' + addHrefSearch()" class="home">首页></a
        ><span class="course"> 技术文章 </span>
      </p>

      <div class="tabs-des">
        <div class="tab-left">
          <a
            @click="navToArticle(item)"
            v-for="item in allArticleList"
            class="artical-list"
            :href="`/article/${item.id}` + addHrefSearch()"
            target="_blank"
            style="display: block"
          >
            <h1 v-html="item.articleTitle"></h1>
            <p class="description" v-html="spliceMesage(item.content)"></p>
            <div class="message">
              <div class="des">
                <span
                  v-if="item.subjectName"
                  :title="item.subjectName"
                  v-html="item.subjectName"
                ></span>
                <div style="display: inline-block; font-size: 14px">
                  关键词：
                </div>
                <em :title="item.keyWordName" v-html="item.keyWordName"></em>
                <i
                  ><img src="@/assets/images/icons/eye@3x.png" alt="" />
                  <div
                    style="display: inline-block"
                    v-html="item.lookNum"
                  ></div>
                </i>
              </div>
              <div v-html="item.createTime.slice(0, 10)" class="timer"></div>
            </div>
          </a>
        </div>

        <div class="tab-right">
          <aside-bar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "assets/css/articleList.less";
import AsideBar from "./AsideBar";
import { mapState } from "vuex";

export default {
  name: "ArticleList",
  components: {
    AsideBar,
  },
  computed: {
    ...mapState({
      allArticleList: (store) => store.ar.allArticleList,
    }),
  },
  data() {
    return {};
  },
  methods: {
    navToArticle(item) {
      return;
      this.$router.push({
        path: `/article/${item.id}`,
        query: { ...this.$route.query },
      });
    },
    spliceMesage(str) {
      return this.removeHTMLTag(str.slice(0, 500));
    },
  },
  created() {
    // this.initArticleList();
    if (!this.allArticleList.length) this.$store.dispatch("INIT_ARTICLES");
  },
};
</script>

<style scoped>
@import url("~@/assets/css/articleList.css");
</style>